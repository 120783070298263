import FeatureImage from "./FeatureImage"
import "../../../components/pages/features/features.css"

// removed these on scroll animation attributes for now until images are all same size: 
// from article tag: data-aos="fade-up" data-aos-duration="600" data-aos-offset="280"
// from first div: data-aos="fade-up" data-aos-duration="850" data-aos-offset="180" data-disable-parallax-down="md"

export default function Feature({ feature, index }) {
  const { title, subtitle, description, img, link, btnText } = feature
  return (
    <article className="row align-items-center card-hover py-5 mb-md-2 mb-lg-3 mb-xl-4" >
      <FeatureImage index={index} img={img} />
      {/* renders the feature image on left supporting info for feature with odd index and on right for even index*/}
      <div className={`py-2 col-md-5 col-xl-4 ${ index % 2 === 1 ? "py-2offset-xxl-1 order-md-1" : null }`} data-aos="fade-up" data-aos-duration="600" data-aos-offset="280">
        <div className={`feature-text ${ index % 2 === 1 ? "me-md-4 me-lg-5 me-xxl-0" : "ms-md-4 ms-lg-5 ms-xxl-0" }`}>
          <div className="text-body-secondary mb-2">{subtitle}</div>
          <h2 className="mb-lg-4">{title}</h2>
          <div className="feature-description mb-0 mb-md-1 mb-lg-3">{description}</div>
          {link ? <a className="card-hover opacity-0 btn btn-lg btn-link px-0 d-md-inline-flex" href={link}>
            {btnText}
            <i className="ai-arrow-right ms-2"></i>
          </a> : null}
        </div>
      </div>
    </article>
  )
}