import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import "./anchor.css";

export default function ActionTextIconAnchor({ title, path }) {
    const LinkComponent = path.includes("#") ? HashLink : Link;

    return (
        <LinkComponent className="d-inline-flex align-items-center fw-semibold text-decoration-none" to={path}>
            {title}
            <i className="ai-arrow-right fs-4 ms-2"></i>
        </LinkComponent>
    )
}