import { useEffect } from "react";
import GoogleAnalytics from "react-ga4";
import { Link } from "react-router-dom";
import "./errors.css";

export default function NotFound() {
    useEffect(() => {
        GoogleAnalytics.event({
            category: "404_ERROR",
            action: window.location.pathname
        }, []);
    });

    return (
        <div className="error-container">
            <h1>Error 404.</h1>
            <p>
                Page not found.
            </p>
            <Link to="/">Navigate Home</Link>
        </div>
    )
}