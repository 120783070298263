import "./footer.css";
import legalPDF from '../../../assets/documents/EQUITRACK Website T&C 8 Feb 2024.pdf';
import privacyPolicyPDF from '../../../assets/documents/EQUITRACK Privacy Policy 8 Feb 2024.pdf';

export default function Footer() {
    return (
        <footer>
            <div className="footer-legal-container">
                <p className="fs-md mb-0"><span>© 2024. All rights reserved. Property of DEV4 Online LTD.</span></p>

                DEV4 Online LTD.<br />
                <a target="_blank" rel="noreferrer" href={privacyPolicyPDF} className="info-link">Privacy Policy</a>
                <br />
                <a target="_blank" rel="noreferrer" href={legalPDF} className="info-link">Legal</a>

                <br /><br />
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className="info-link">Privacy Policy</a> and <a href="https://policies.google.com/terms" className="info-link">Terms of Service</a> apply.
            </div>
            <aside>
                <div>
                    <a className="icon-circle-btn px-2" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/equitrack/?viewAsMember=true">
                        <i className="ai-linkedin"></i>
                    </a>

                    <a className="icon-circle-btn px-2" target="_blank" rel="noreferrer" href="https://www.facebook.com/dev4.online/">
                        <i className="ai-facebook"></i>
                    </a>

                    <a className="icon-circle-btn px-2" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=nCrQhHcSwjc">
                        <i className="ai-youtube"></i>
                    </a>
                </div>
            </aside>
        </footer>
    )
}