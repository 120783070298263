import { useEffect } from "react";
import GoogleAnalytics from "react-ga4";
import "../components/pages/features/features.css"
import Feature from "../components/pages/features/Feature";
import equitrack from "../assets/img/features/EQMob2.webp"
import devSupport from "../assets/img/features/devTeamSupport.webp"
import discussingCustomising from "../assets/img/features/discussingCustomising.webp"
import singleDb from "../assets/img/features/singleDb.webp"
import qrScan from "../assets/img/other/QRScanner.webp"
import utilisation from "../assets/img/features/utilisationBoard.webp"
import projectManagement from "../assets/img/features/projectManagement.webp"
import googleMaps from "../assets/img/features/googleMaps.webp"
import systemIntegration from "../assets/img/features/systemIntegration.webp"
import physicalFiles from "../assets/img/features/physicalFiles.webp"


const features = [
    {
        title: "QR scanning", subtitle: "Reduce data entry",
        description: <div>
            <p>QR code scanning has become a ubiquitous and valuable technology in the management of assets.  Using our QR functions you can navigate directly to an item of equipment with an assured accuracy of its location.</p>
            <p>Key features include:</p>
            <ul> <li>scanning to navigate quickly to an asset.</li>
                <li>identifying the location from your phones GPS.</li>
                <li>creating and printing your own QR codes.</li></ul>

        </div>,
        img: qrScan
    },
    {
        title: "GPS tracking and IoT monitoring", subtitle: "Sensor integration",
        description: "We have partnered with suppliers of GPS trackers and sensors which work seamlessly with equi-track.  All we have to do is register each tracking device with equi-track, turn it on and then you can see your asset movement on a map.  We will work with you to find the right device for each type of asset.  The history of each tracker is recorded to ensure you have full traceability for invoicing and auditability.",
        img: equitrack, link: "https://teltonika-gps.com/", btnText: "Find out more"
    },
    {
        title: "Real-time utilisation information", subtitle: "Reduce waste",
        description: <div><p>Recording accurate current and historical data on the movement and condition of your equipment will enable utilisation reporting and forecasting, feeding into the bigger picture.</p>
            <p>Awareness of asset utilisation is important for:</p>
            <ul><li>making better Capex decisions.</li>
                <li>informing shareholders.</li>
                <li>building business cases.</li></ul>

        </div>,
        img: utilisation, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Job and project management", subtitle: "Project clarity",
        description: <div><p>The challenges for asset management  are often who, when and where.  Equi-track utilises QR scanning, GPS location and manual updates to validate:</p>
            <ul> <li>which client your equipment is with.</li>
                <li>the project it is being charged to.</li>
                <li>the value and cost while it's on the job.</li>
                <li>any movements and faults whilst on a project.</li></ul>

        </div>,
        img: projectManagement, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Integration with Google maps to view location of assets", subtitle: "Location accuracy",
        description: <div><p>Equi-track harnesses the familiarity of google maps to display the location and condition of your assets.</p>
            <p>We have optimised the google maps technology to use on any device so you can see the latest recorded location of your equipment on a phone, tablet or computer</p>
            <p>There are 3 methods of recording the location of your equipment:</p>
            <ul> <li>GPS tracking devices.</li>
                <li>using the GPS on your phone or tablet.</li>
                <li>manually updating the location from a map or postcode.</li></ul>


        </div>,
        img: googleMaps, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Integration with your existing systems", subtitle: "Sharing information across your company",
        description: <div><p>Equi-track has been built with a modern layered architecture that allows secure sharing of data with other systems.</p>
            <p>Having worked with companies who require systems to integrate with their existing Enterprise systems, such as Enterprise Resource Planning or Supply Chain Management Systems, we understand this requirement and have modelled equi-track to make this process a seamless one.</p>
        </div>,
        img: systemIntegration, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Option for customisation", subtitle: "A system to work for you",
        description: <div><p>Our team are expert software engineers who have years of experience in bespoke software development. This has led to an understanding of individual requirements and how one size does not always fit all.</p>
            <p>The journey to customisation is a process which we have simplified and are always happy to discuss new ideas and improvements.</p>
            <p></p>
        </div>,
        img: discussingCustomising, link: "https://equi-track.co.uk/roadmap/", btnText: "See our roadmap"
    },
    {
        title: "You get your own database", subtitle: "Information security",
        description: "In our premium package, equi-track is set up in the cloud to attach to your own database.  This architecture gives you secure flexibility to align the system with your own company security and regulatory requirements.  With our cloud package we can ensure your database is located in the right country to meet you data protection legislation.",
        img: singleDb, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Migration of data", subtitle: "Easy transition",
        description: "We know that the move to a new robust, cloud based system is a significant improvement to your company and the way it monitors your assets so our team of data specialists are on hand to migrate your existing data to equi-track.  This is something we have a lot of experience in and are really good at.",
        img: physicalFiles, link: "https://dev4.online/", btnText: "Find out more"
    },
    {
        title: "Support from experienced development team", subtitle: "Confidence",
        description: "Continuity of service, and a product that delivers is important. Our experienced development team are on hand to offer comprehensive support when you onboard our product and for the rest of its life with you.",
        img: devSupport, link: "https://dev4.online/", btnText: "Find out more"
    }
    //{
    //    title: "Visualisation of asset management", subtitle: "Maintenance and condition",
    //    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //    img: assetVisualisation, link: "https://dev4.online/", btnText: "Find out more"
    //}
]

export default function Features() {
    useEffect(() => {
        GoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname, title: "Case Studies" });
    }, []);

    return (
        <main className="below-navbar d4-container">
            <div className="features-container">
                <div className="features-section">
                    <h1 className="text-center">Features</h1>
                    <div className="features-subtitle-text">
                        <p className="text-center">
                            Equi-track is a cloud-based equipment tracking software solution that communicates with GPS trackers and IoT sensors, to give an accurate location and condition of your assets
                        </p>
                        <p className="text-center pb-3 pb-md-4 pb-lg-5 mb-lg-0 mb-xl-2 mb-xxl-4">
                            Our features focus on efficiency, innovation, and the integration of advanced technologies in the field of asset management
                        </p>
                    </div>
                </div>

                <div>
                    {features.map((feature, index) => {
                        return <Feature feature={feature} index={index} key={feature.title}/>
                    })}
                </div>

            </div>

        </main>
    )
}