import "./about.css";
import DeliverOnTime from "./items/DeliverOnTime";
import DesignTogether from "./items/DesignTogether";
import FutureProofIt from "./items/FutureProofIt";



export default function About() {
    return (
        <section id="about">
            <div className="d4-container">
                <DesignTogether />
                <DeliverOnTime />
                <FutureProofIt />
            </div>
        </section>
    );
}