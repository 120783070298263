import "./services.css";
import Service from "./Service";
import Section from "../../../common/Section/Section";

// Icons for each service
import responsiveWebAppsIcon from "../../../../assets/img/icons/services/RWA.svg";
import databaseDevIcon from "../../../../assets/img/icons/services/DD.svg";
import microsoftOfficeAppsIcon from "../../../../assets/img/icons/services/MOA.svg";
import cloudAppsIcon from "../../../../assets/img/icons/services/CA.svg";
import technicalDirectionIcon from "../../../../assets/img/icons/services/TD.svg";
import projectMgmtIcon from "../../../../assets/img/icons/services/PM.svg";

// Thumbnail photos for each service.
import managementMeeting from "../../../../assets/img/other/ManagementMeeting2.webp"
import qrScanner from "../../../../assets/img/other/QRScanner.webp"
import lorryGPS from "../../../../assets/img/other/lorryGPS.webp"
import manOnLaptop from "../../../../assets/img/other/ManOnLaptopWarehouse.webp"
import boxesAndBarcode from "../../../../assets/img/other/BoxesAndBarcode.webp"
import windTurbineAndShip from "../../../../assets/img/other/WindTurbineAndShipSunset.webp"

/**
 * List of service objects that will be rendered on to the document.
 * */
const services = [
    {
        icon: projectMgmtIcon,
        title: "Location accuracy",
        subtitle: "GPS tracking integration",
        description: "Integrates with our own tool that connects to multiple GPS trackers",
        hoverImage: lorryGPS
    },
    {
        icon: databaseDevIcon,
        title: "Reduce data entry",
        subtitle: "QR scanning",
        description: "Scan a QR code using your phone to identify location and status of each asset",
        hoverImage: qrScanner
    },
    {
        icon: technicalDirectionIcon,
        title: "Reduce waste",
        subtitle: "Utilisation reporting",
        description: "Extract useful utilisation reports to identify waste and increase profits",
        hoverImage: managementMeeting
    },
    {
        icon: cloudAppsIcon,
        title: "Preventative maintenance",
        subtitle: "Maintenance and condition",
        description: "Alerts on maintenance requirements prevent downtime and expensive and unnecessary movement of assets",
        hoverImage: manOnLaptop
    },
    {
        icon: microsoftOfficeAppsIcon,
        title: "Project clarity",
        subtitle: "Project integration",
        description: "Manage your projects with equi-track and capitalise on your assets. All movement of your assets can be aligned with your projects.",
        hoverImage: windTurbineAndShip
    },
    {
        icon: responsiveWebAppsIcon,
        title: "Intelligent automation",
        subtitle: "Sensor integration",
        description: "Receive real-time data on your assets - such as temperature, humidity and movement",
        hoverImage: boxesAndBarcode
    }
]

export default function Services() {
    return (
        <Section
            title="Our Mission"
            subtitle="&quot;to accelerate the technological automation of asset management and equipment tracking&quot;"
            scrollID="services"
            className="text-center fs-5 py-6"
        >
            <div className="services-container limit-width">
                {
                    // Bind service to a Service component.
                    services.map(service => (
                        <Service service={service} key={service.title} />
                    ))
                }
            </div>
        </Section>
    )
}