import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import "./anchor.css";

export default function PrimaryAnchorBtn({ title, path, icon, rounded, theme, className, onClick }) {
    theme ??= "primary"; // Default theme, if no theme is provided.

    // If link is using a HashLink rather than a normal link.
    const LinkComponent = path.includes("#") ? HashLink : Link;
    
    return(
        // {/* You can not use standard anchor tags as that will cause the app to request the page from the server rather than React. */}
        <LinkComponent to={path} className={`btn btn-${theme} ${className} ${rounded ? 'rounded' : 'rounded-0'}`} onClick={onClick}>
            {icon && <i className={`${icon} me-3`}></i>}
            {title}
        </LinkComponent>
    )
}