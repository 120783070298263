import "./hero.css";
import heroSmall from "../../../../assets/img/banners/hero_small.webp";
import heroMedium from "../../../../assets/img/banners/hero_medium.webp";
import heroLarge from "../../../../assets/img/banners/hero_large.webp";
import { HashLink } from 'react-router-hash-link';
import { Parallax } from 'react-parallax';

export default function Hero() {
    return (
        <Parallax
            bgImage={heroLarge}
            bgImageAlt="Hero Image: Tracking assets and vehicles"
            strength={300}
            bgImageSrcSet={`${ heroSmall } 500w, ${ heroMedium } 1000w, ${ heroLarge } 2560w`}
        >
            {/* ^ Smaller resolutions: https://stackoverflow.com/questions/28650327/responsive-images-srcset-not-working seems to be fixed. */}
            <div id="hero">
                <div className="card-holder">
                    <div className="card px-4 py-4 px-md-5 px-lg-3 py-lg-2">
                        <div className="card-body text-center text-sm text-white">
                            { /* Hero Title & Introduction */}
                            <h1 className="card-title text-white display-6 mb-4">equi-track has got your assets covered</h1>
                            <p className="fw-light p-1 mb-lg-4">Sit back and let the data flow in</p>

                            { /* Contact Button & Number */}
                            <div className="d-flex align-items-center justify-content-center py-2 pb-lg-0">
                                <HashLink className="btn btn-lg btn-light" to="/#contact">Book a demonstration</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Parallax>
    );
}
