import doPostRequest from "../../../../services/doPostRequest";
import { AlertModel } from "../../../common/Alert/Alert";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import GoogleAnalytics from "react-ga4";

/**
 * Handle the contact form submission.
 * @param {Object} submission Should contain information gathered from the form.
 * @param {Function} setLoading Function to update state to set if a loading bar is shown or not.
 * @param {Function} setAlert Function to update state to set if an alert / modal is shown or not.
 */
export default async function handleSubmit(submission, setLoading, setAlert, requireCaptcha) {
    GoogleAnalytics.event({
        category: "CONTACT_FORM_SUBMIT",
        action: "N/A"
    }, []);

    setLoading(true);

    submission.reCaptchaV3Token = await ReactRecaptcha3.getToken();

    // Make post request.
    const response = await doPostRequest("/api/contact", submission);

    // Successful response.
    if (response.responseCode === 200) {
        setAlert(new AlertModel(
            true,
            "Submission Recieved",
            "Thank you for your message, we will respond to your inquiry within seven days."
        ));
        // Bad request.
    } else if (response.responseCode === 400) {
        // Should not be possible as this logic is prevented in the front-end form validation aswell.
        // But for some reason if someone tampered with the inputs they will get this message.
        setAlert(new AlertModel(
            true,
            "Error",
            "Form validation error, please refresh your page and try again."
        ));

        // Debugging data.
        console.log(response.data);
        // All other errors.
    } else if (response.responseCode === 403) {
        // Requires ReCaptcha
        requireCaptcha(true);
    } else {
        setAlert(new AlertModel(
            true,
            "Server Error",
            "We're sorry, an error has occured on our side, please try again later."
        ));
    }

    setLoading(false);
}