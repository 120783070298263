import "./partners.css";

export default function Partner({ company }) {
    const { name, description, website, logo } = company
    return (
        <div className="single-partner py-4">
            <div className="partner-img h-100 d-flex flex-column m-2 md-px-4 ">
                <a target="_blank" href={website} alt={`${name} website link`} >
                    <img src={logo} alt={name} className="img-fluid" />
                </a>
            </div>
            <p className="partner-description mx-2 my-3">
                {description}
            </p>
        </div>
    )
}