import "./about.css";

export default function AboutItem({ thumbnail, heading, children, floatingIcon }) {
    return (
        <div data-aos="fade-up" data-aos-duration="600" data-aos-offset="280" className="about-item limit-width">
            <div className="content">
                <h1 className="h1 pb-sm-2 pb-lg-3">{heading}</h1>

                { children }
            </div>

            <div className="position-relative thumbnail-container">
                <div className="thumbnail zindex-2 position-relative" style={{
                    backgroundImage: `url(${thumbnail})`
                }}>

                </div>

                {floatingIcon}
            </div>
        </div>
    )
}