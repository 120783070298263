import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

export default function NavbarLink({ path, title, closeNavbar, external }) {
  // If link is using a HashLink rather than a normal link.
  const LinkComponent = path.includes("#") ? NavHashLink : NavLink;

  if (external) {
    return (
      <a href={path} target="_blank" rel="noreferrer" className="custom-nav-link text-decoration-none mx-3">
        {title}
      </a>
    );
  }

  const handleClick = (event) => {
    if (path === window.location.pathname) {
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (typeof closeNavbar === 'function') {
      closeNavbar();
    }
  };

  // Custom wrapper component to handle click event
  const CustomLink = ({ to, exact, activeClassName, className, children }) => {
    const handleLinkClick = (event) => {
      handleClick(event);
    };

    return (
      <LinkComponent
        to={to}
        exact={exact}
        activeClassName={activeClassName}
        className={className}
        onClick={handleLinkClick}
      >
        {children}
      </LinkComponent>
    );
  };

  return (
    <CustomLink
      to={path}
      exact
      activeClassName={!path.includes("#") ? "active" : ""}
      className="custom-nav-link text-decoration-none mx-3"
    >
      <span>{title}</span>
    </CustomLink>
  );
}
