export function Step({ step }) {
  const { title, description, icon } = step
  return (
    <div className="step">
      <div className="step-number">
        <div className="step-number-inner fs-4">
          <i className={icon}></i>
        </div>
      </div>
      <div className="step-body">
        <h3 className="h5 pb-1 mb-2">{title}</h3>
        <p className="mb-0">{description}</p>
      </div>
    </div>)
}