import NavbarLink from "./NavbarLink";

export default function NavigationLinkContainer({ NAVIGATION_LINKS, mobileNavbar, closeNavbar }) {
    if (!mobileNavbar) {
        // Desktop
        return (
            <div className="nav-container">
                {
                    NAVIGATION_LINKS.map((link, index) => (
                        <NavbarLink title={link.title} path={link.path} external={link.external} key={index}></NavbarLink>
                    ))
                }
            </div>
        )
    } else {
        // Mobile
        return NAVIGATION_LINKS.map((link, index) => (
            <NavbarLink title={link.title} path={link.path} key={index} closeNavbar={closeNavbar} external={link.external}></NavbarLink>
        ));
    }
}