import "./forms.css";

export default function FormGroup({ children, columns, colsGap, marginTop }) {
    return (
        <div className="form-group" style={{
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridColumnGap: colsGap,
            marginTop
        }}>
            {children}
        </div>
    )
}