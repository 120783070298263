/*
 * Tools section.
 * This section displays all the technology DEV4 online uses.
 */

import "./tools.css";
import Tool from "./Tool";
import PrimaryAnchorBtn from "../../../common/Anchors/PrimaryAnchorBtn";

/*
 * You can use a tool called Trimmy, which can be found at https://trimmy.io/, 
 * to remove the white border or padding on the logos of the images.
 * It's recommended that images have the same or similar aspect ratios 
 * to ensure consistency and avoid any distortion or stretching. 
 * Therefore, when importing images, 
 * keep in mind the importance of maintaining consistent aspect ratios.
 */

import azureLogo from "../../../../assets/img/tools/AzureLogo.webp";
import eyeLogo from "../../../../assets/img/tools/eyeLogo.webp"
import beamOnboardLogo from "../../../../assets/img/clients/beam-onboard-logo.webp"
import teltonikaLogo from "../../../../assets/img/clients/teltonika-logo.webp"
import androidLogo from "../../../../assets/img/tools/androidLogo.webp"
import iosLogo from "../../../../assets/img/tools/iosLogo.webp"

export default function Tools() {
    return (
        <section data-aos="fade-up" className="tools-section">
            <div className="tools-card d4-container">
                <div className="tools-container">
                    {/*List of tools.*/}
                    <Tool link="https://azure.microsoft.com/en-gb/" name="Azure cloud service" logo={azureLogo} />
                    <Tool link="https://teltonika-gps.com/products/trackers?page=3&sort=default" name="Tracking devices" logo={teltonikaLogo} />
                    <Tool link="https://teltonika-gps.com/products/accessories/sensors-beacons/eye" name="Sensors" logo={eyeLogo} />
                    <Tool link="https://beamonboard.com/" name="Beam Onboard" logo={beamOnboardLogo} />
                    <Tool link="https://www.android.com/intl/en_uk/" name="Android" logo={androidLogo} />
                    <Tool link="https://www.apple.com/uk/" name="iOS" logo={iosLogo} />
                </div>

                <div className="tools-section-descriptor">
                    <h1 className="h1 my-4">
                        The tools equi-track is compatible with
                    </h1>
                    <p className="pb-3 mb-3 mb-lg-4">
                        equi-track is built with a robust and reliable tech stack that includes a wide range of tools and technologies.
                        The secure data connections transport data from assets via our cloud based solution to your desk and mobile phone.
                    </p>
                    <PrimaryAnchorBtn title="Find out more" path="/#contact" rounded="true" theme="secondary" />
                </div>
            </div>
        </section>
    )
}