import "./roadmap.css"
import progressImg from "../../../assets/img/other/inProgress.svg"
import techVisionImg from "../../../assets/img/other/techVision.svg"
import { Step } from "./Step"

export function Roadmap() {
  const roadmapSteps = [
    {
      title: "Advanced utilisation reporting",
          description: "Make informed decisions based on comprehensive utilisation reports. This feature will transform raw data into actionable insights, enabling you to allocate resources strategically, improve asset performance, and drive better business outcomes.",
      icon: "ai-chart"
    },
    {
      title: "Management of spares and kits",
        description: "Streamline your workflows with kit management. Create, assemble, and manage kits effortlessly. Whether you're bundling components for a specific task or preparing for routine maintenance, this feature will ensure that kits are assembled accurately and efficiently.",
      icon: "ai-tool"
    },
    {
      title: "Integration with Power BI",
        description: "Tailor your asset management dashboards to suit your unique business needs. Power BI's flexibility allows you to create personalised views that focus on the metrics that matter most to your organisation. Gain a comprehensive overview or dive deep into specific aspects of your asset portfolio. ",
      icon: "ai-database"
    },
    {
      title: "Stock management",
        description: "Revolutionise your asset management strategy by seamlessly integrating stock management into the mix. Our feature-rich solution will combine robust asset tracking with comprehensive stock management capabilities, providing a unified platform to optimise your resources, reduce costs, and enhance overall operational efficiency.",
      icon: "ai-globe"
    },
    {
      title: "Predictive maintenance",
        description: "Our cutting-edge solution leverages the Internet of Things to transform the way you maintain and manage assets. Our predictive maintenance feature, fueled by IoT sensors, will provide proactive alerts, notifying you of potential issues before they escalate. Prevent costly breakdowns and keep your operations running smoothly.",
      icon: "ai-activity"
    },
    {
      title: "Integration with finance reporting",
        description: "Our integration will seamlessly synchronise asset-related financial data with your finance reporting system, saving time and reducing the risk of errors associated with manual data handling.",
      icon: "ai-vertical-align-bottom"
    }
  ]
  return (
    <main className="below-navbar card border-0 mb-4" id="steps-icons">
      <div className="container w-100 pb-md-2 pt-lg-2 pb-lg-4 py-xl-4 py-xxl-5">
        <h2 className="h1 mb-n2 pt-5 text-center">Roadmap</h2>
              <p className="py-3 text-center">Experience the forefront of innovation with Equi-Track. Our commitment to continuous development ensures that we stay ahead of the game.</p>
        <div className="card-body mt-3 d-flex">
          <div className="steps steps-hoverable">
            {roadmapSteps.map((step) => {
              return <Step key={step.title} step={step} />
            })}
          </div>
          <div className="d-flex flex-column justify-content-around">
            <img className="img-fluid progress-img mx-5" src={progressImg} alt={progressImg} />
            <img className="img-fluid progress-img mx-5" src={techVisionImg} alt={techVisionImg} />
          </div>
        </div>
      </div>
    </main>
  )
}