import ApiResponse from "./ApiResponse";

/**
 * Post data to a web resource.
 * @param {String} url Location of said resource.
 * @param {Object} body Javascript Object representing the form body.
 */
export default async function doPostRequest(url, body) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        });

        // If the request is okay or the form has illegal properties.
        if (response.ok || response.status === 400) {
            const responseData = await response.json();

            if (response.ok) return new ApiResponse(false, 200, responseData);
            if (response.status === 400) return new ApiResponse(true, 400, responseData);
        }
        // Any other response code.
        else {
            throw new Error(response.status);
        }

    } catch (error) {
        return new ApiResponse(true, parseInt(error.message), "There has been an error while attempting to access this resource.");
    }
}