import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// reportWebVitals & serviceWorkerRegistration included by default, will need to research if they are needed for our enviroment. Leaving in for now.
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Animation Library, https://michalsnik.github.io/aos/.
import AOS from 'aos';
import 'aos/dist/aos.css';

// Bind React to a root element.
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render project to root element.
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>);

// Utilise animation library, https://github.com/michalsnik/aos.
AOS.init();

// Below was included by default.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
