import AboutItem from "../AboutItem";
import PrimaryAnchorBtn from "../../../../common/Anchors/PrimaryAnchorBtn";
import redSemiCircle from "../../../../../assets/img/icons/shapes/redSemiCircle.svg";
import FutureProofItThumbnail from "../../../../../assets/img/other/MultipleMethods.webp";

export default function FutureProofIt() {
    return (
        <AboutItem
            heading="Multiple ways to accurately track your assets"
            thumbnail={FutureProofItThumbnail}
            floatingIcon={
                <div className="zindex-3 d-none d-lg-block position-absolute top-0 start-50 translate-middle w-auto ms-2">
                    <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280" className="aos-init aos-animate">
                        <img src={redSemiCircle} alt="Circle Icon" width="108px" />
                    </div>
                </div>
            }
        >

            <div>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    The many methods to capture your asset data gives you the best chance for accuracy. This feeds through to your utilisation figures, your planned and predictive maintenance and project status reports.
                </p>
                <p className="fs-xl" style={{ width: '50ch' }}>
                    1. Manage your assets through the projects they are assigned to </p>
                <p className="fs-xl" style={{ width: '50ch' }}>
                    2. Create and scan QR codes, harnessing the GPS signal on your mobile phone </p>
                <p className="fs-xl" style={{ width: '50ch' }}>
                    3. Use GPS tracking devices which will send data to equi-track and show you the current location and a wealth of additional data that helps you evaluate asset condition and recent activity </p>
                <p className="fs-xl" style={{ width: '50ch' }}>
                    4. Simply open equi-track on your phone or tablet to view and update the data </p>

                <PrimaryAnchorBtn title="Get in touch" theme="secondary" path="/#contact" />
            </div>
        </AboutItem>
    )
}