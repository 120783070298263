import { useMemo, useState } from "react";

export default function Slider({ numOfAssets, setNumOfAssets }) {
  // sets the number to index into to in the assets array
  const [assetLabelsIndex, setAssetLabelsIndex] = useState(0)

  // labels wrapped in useMemo to prevent re-rendering as array doesn't change 
  const labels = useMemo(() => {
    return ["250", "1000", "5000", "10,000+"]
  }, [])

  // updates the asset label when user updates number of assets 
  const handleSliderChange = (event) => {
    let currentAssetNumber = (event.target.value)
    setAssetLabelsIndex(currentAssetNumber)
    setNumOfAssets(currentAssetNumber)
    return numOfAssets
  }
  return (
    <>
      <p className="form-label text-center fs-5 text-white mt-4">How many assets do you want to track?</p>
      <div className="d-flex flex-column w-100">
        <label
          htmlFor="customRangePriceSlider"
          className="form-label text-white text-center fs-5 pb-2 py-lg-4">{`${ labels[assetLabelsIndex] } assets`}
        </label>
        <input
          value={assetLabelsIndex}
          onChange={handleSliderChange}
          type="range"
          className="form-range"
          min="0"
          max={labels.length - 1}
          id="customRangePriceSlider"
        />
        <label className="fs-6 text-white text-center d-flex justify-content-between flex-end pb-0 pt-2">
          <p>250</p>
          <p>10,000+</p>
        </label>
      </div>
    </>
  )
}