import { useState } from "react";
import "./Navbar.css";
import logo from "../../../assets/img/branding/Logos/equ-logo-blu.webp";
import NavigationLinkContainer from "./NavigationLinkContainer";
import PrimaryAnchorBtn from "../Anchors/PrimaryAnchorBtn";
import { Twirl as Hamburger } from 'hamburger-react';

const NAVIGATION_LINKS = [
    {
        title: "Home",
        path: "/"
    },
    {
        title: "Services",
        path: "/#services"
    },
    {
        title: "Partners",
        path: "/#partners"
    },
    {
        title: "Pricing",
        path: "/#contact"
    },
    {
        title: "Roadmap",
        path: "/Roadmap"
    },
    {
        title: "Features",
        path: "/Features"
    },
    {
        title: "LinkedIn",
        path: "https://www.linkedin.com/company/equitrack/?viewAsMember=true",
        external: true
    }
];

export default function Navbar() {
    const [mobileNavVisible, setMobileNavVisiblity] = useState(false);

    return (
        <nav className="bg-light fixed-top d-flex">
            <div id="lhs">
                <img className="navbar-brand logo" src={logo} alt="equitrack Logo" />
                <div className="d-none d-md-flex ms-3">
                    <NavigationLinkContainer NAVIGATION_LINKS={NAVIGATION_LINKS} />
                </div>
            </div>

            <div id="rhs">
                <div className="d-none d-md-flex">
                    <PrimaryAnchorBtn title="Contact" path="/#contact" icon="ai-phone" />
                </div>
                <div className="d-block d-md-none">
                    <Hamburger toggled={mobileNavVisible} toggle={setMobileNavVisiblity} direction="left" />

                    {
                        mobileNavVisible &&
                        <div id="mobileNav" className="bg-light">
                            <ul className="navbar-nav py-1">
                                <NavigationLinkContainer NAVIGATION_LINKS={NAVIGATION_LINKS} mobileNavbar={true} closeNavbar={() => setMobileNavVisiblity(false)} />
                                <PrimaryAnchorBtn title="Contact" path="/#contact" icon="ai-phone" className="mt-auto" onClick={() => setMobileNavVisiblity(false)} />
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </nav>
    )
}
