import './alert.css';
import { useEffect } from "react";
import { clone } from '../../../services/utils';

/**
 * Alert popup using bootstrap cards.
 * @param {String} title Card Title.
 * @param {String} message Card Message.
 * @param {Boolean} visible If the alert is shown or not.
 * @param {Function} onClose Function to close the alert. i.e, set visible = false.
 */
export function Alert({ title, message, visible, onClose }) {

    // Prevent background scrolling if visible.
    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'unset';
    }, [visible]);

    // Only show modal if visible = true.
    if (visible) return (
        <div className="position-fixed fixed-top w-100 h-100 alert-container">
            <div className="background-blur"></div>
            <div className="card text-center alert-card rounded-0">
                <div className="card-body">
                    <h5 className="card-title">{ title }</h5>
                    <p className="card-text">
                        { message }
                    </p>

                    <button onClick={onClose} type="button" className="btn btn-primary">Close</button>
                </div>
            </div>
        </div>
    );

    if (!visible) return ("");
}

/**
 * Class to model the data inside of an Alert.
 */
export class AlertModel {
    constructor(visible, title, message) {
        this.visible = visible;
        this.title = title;
        this.message = message;
    }

    toggleVisibility() {
        this.visible = !this.visible;

        return clone(this);
    }

    static initialiseAlert() {
        return new AlertModel(false, null, null);
    }
}