import "./buttons.css";

// Use for a completely blank / transparrent icon button.

export default function TransparentButton({ icon, onClick }) {
    return (
        <button className="btn-transparent" onClick={onClick}>
            <i className={icon}></i>
        </button>
    )
}