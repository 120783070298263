import AboutItem from "../AboutItem";
import pizzaIcon from "../../../../../assets/img/icons/shapes/pizza.svg";
import ActionTextIconAnchor from "../../../../common/Anchors/ActionTextIconAnchor";
import DesignTogetherThumbnail from "../../../../../assets/img/other/gps.webp";

export default function DesignTogether() {
    return (
        <AboutItem
            heading="Track anything with the right GPS device"
            thumbnail={DesignTogetherThumbnail}
            floatingIcon={
                <div className="zindex-3 d-none d-lg-block position-absolute top-0 end-0 translate-middle-y w-auto pe-1 me-4">
                    <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280" className="aos-init">
                        <img src={pizzaIcon} alt="Pizza Icon" width="107" />
                    </div>
                </div>
            }
        >
            { /* About Item Content. */}
            <div>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    equi-track uses a bespoke tool for connecting to different types of devices for GPS Tracking.
                </p>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    It receives data from trackers connected to: 
                </p>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    Non-powered machinery or equipment | Powered machinery | Vehicles | Boats | Personal trackers.
                </p>
              

                <br />
                <ActionTextIconAnchor title="Book a free demonstration" path="/#contact" />
            </div>
        </AboutItem>    
    )
}