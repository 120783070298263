import "./partners.css";
import Partner from "./Partner.js";
import Section from "../../../common/Section/Section";

/* Client Logos */
import beamOnboardLogo from '../../../../assets/img/clients/beam-onboard-logo.webp'
// import teltonikaLogo from '../../../../assets/img/clients/teltonika-logo.webp'
import dev4logo from "../../../../assets/img/branding/Logos/D4 Full Logo Colour.webp"

const partnerAndAssociatesList = [
    { name: "Beam Onboard", description: "Experts in GPS and communication devices, ensuring continuous communication.", website: "https://beamonboard.com/", logo: beamOnboardLogo },
    // { name: "Teltonika", description: "Global leaders of providing unique IoT solutions that contribute to making people's lives easier.", website: "https://teltonika-gps.com/", logo: teltonikaLogo },
    { name: "DEV4 Online Ltd", description: "Experienced software engineers developing solutions for over 20 years", website: "https://dev4.online/", logo: dev4logo }
]

export default function Partners() {
    return (
        <Section title="Our Partners & Associates" subtitle="We work with other companies to provide the full experience of integrating software with IoT" scrollID="partners" data-aos="fade-up">
            <div className="partners-outer-container bg-secondary pb-md-2 pt-lg-2 pb-lg-4 py-xl-4 py-xxl-5">
                <div className="partners-container">
                        {partnerAndAssociatesList.map((company) => {
                            return (
                                <Partner company={company} key={company.name} />
                            )
                        })}
                </div>
            </div>
        </Section >
    )
}